<template>
  <div id="app">
    <div class="content">
      <router-view />
    </div>
    <CusFooter />
  </div>
</template>

<script>
import CusFooter from '@/components/CusFooter/CusFooter.vue'

export default {
  name: 'App',
  components: {
    CusFooter,
  },
  mounted () {
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Microsoft YaHei', sans-serif;
}
#app {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.content {
  height: calc(100vh - 65px);
  border: 1px solid transparent;

}
.content > div:first-child {
  height: 100%;
}
</style>
