import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginInfo: localStorage.RightExamLoginInfo ? JSON.parse(localStorage.RightExamLoginInfo) : null,
    caseDetail: true, // 案件详情
    caseAccept: true, // 案件领取
    caseDel: true, // 案件删除
    caseExport: true, // 案件导出
    accountAdd: true, // 账号创建
    accountEdit: true, // 账号编辑
    accountDel: true, // 账号删除
    roleAdd: true, // 角色创建
    roleEdit: true, // 权限修改
    roleDel: true, // 角色删除
  },
  getters: {
    getLoginInfo(state) {
      return state.loginInfo
    },
    getCaseDetail(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('案件详情')) {
          state.caseDetail = true
        } else {
          state.caseDetail = false
        }
      }
      return state.caseDetail
    },
    getCaseAccept(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('案件领取')) {
          state.caseAccept = true
        } else {
          state.caseAccept = false
        }
      }
      return state.caseAccept
    },
    getCaseDel(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('案件删除')) {
          state.caseDel = true
        } else {
          state.caseDel = false
        }
      }
      return state.caseDel
    },
    getCaseExport(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('案件导出')) {
          state.caseExport = true
        } else {
          state.caseExport = false
        }
      }
      return state.caseExport
    },
    getasaccountAdd(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('账号创建')) {
          state.accountAdd = true
        } else {
          state.accountAdd = false
        }
      }
      return state.accountAdd
    },
    getAccountEdit(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('账号编辑')) {
          state.accountEdit = true
        } else {
          state.accountEdit = false
        }
      }
      return state.accountEdit
    },
    getAccountDel(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('账号删除')) {
          state.accountDel = true
        } else {
          state.accountDel = false
        }
      }
      return state.accountDel
    },
    getRoleAdd(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('角色创建')) {
          state.roleAdd = true
        } else {
          state.roleAdd = false
        }
      }
      return state.roleAdd
    },
    getRoleEdit(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('权限修改')) {
          state.roleEdit = true
        } else {
          state.roleEdit = false
        }
      }
      return state.roleEdit
    },
    getRoleDel(state) {
      if (state.loginInfo) {
        // 如果是管理员
        if (state.loginInfo.accountType == 4) {
          return true
        }
        if (state.loginInfo.roles.includes('角色删除')) {
          state.roleDel = true
        } else {
          state.roleDel = false
        }
      }
      return state.roleDel
    },
  },
  mutations: {
    setLoginInfo(state, info) {
      state.loginInfo = info
    }
  },
  actions: {
    setLoginInfoFunc(context, info) {
      context.commit('setLoginInfo', info)
    }
  }
})
